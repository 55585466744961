// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

require('./parts/_cookie-banner');


import Alpine from 'alpinejs'
import mask from '@alpinejs/mask'

Alpine.plugin(mask)

import donationForm from './donation-form.js'

Alpine.data('donationForm', donationForm)

window.Alpine = Alpine
Alpine.start()

if(document.getElementById('demo-scroll')){
    document.getElementById('demo-scroll').addEventListener('click', () => {
        window.scrollTo({ top: document.getElementById('sec-demo').getBoundingClientRect().top, behavior: "smooth" });
    })
}


import hljs from "highlight.js";
import {CopyButtonPlugin} from './vendor/hljs-copy-temp';

hljs.addPlugin(new CopyButtonPlugin());
hljs.highlightAll();
