
import Cookies from "js-cookie";

const cookieBannerDiv = document.querySelector('.cookie-banner');
const consentName = 'paywithterra-cookie-ok'
if(cookieBannerDiv && ! Cookies.get(consentName)){
    cookieBannerDiv.classList.remove('d-none')

    document.querySelector('#cookie-banner-btn-ok').addEventListener('click', () => {
        cookieBannerDiv.classList.add('d-none');

        Cookies.set(consentName, '1', { expires: 60 })
    })
}
