import {api} from './api';

export default () => ({
    infoTotal: 0,
    infoCount: 0,
    //stats: [],
    statsAll: {
        mainnet: [{
            asset: "LUNA",
            total: 0,
        }],
        testnet: [],
    },

    amount: "2.00",
    asset: "uluna",
    network: "mainnet",
    availableNetworks: ["mainnet", "testnet"],

    get stats(){
        return this.statsAll[this.network];
    },

    get inTestnet() {
        return this.network === "testnet";
    },
    formatTotalAmt(amount){
        return Number(Number(amount) / 1000000).toFixed(3)
    },
    get infoTotalNormal() { return Number(this.infoTotal / 1000000).toFixed(3) },
    get btnText() {
        let addon = "";
        if(this.network === "testnet") addon = " (testnet)";
        return this.loading ? 'Loading...' : `Donate with Terra${addon}`; },
    loading: true,

    error: null,
    success: null,

    init() {
        this.loadInfo()

        if(!! window.payWithTerra) {
            this.loading = false;
        } else {
            window.addEventListener('payWithTerra-ready', () => {
                this.loading = false;
            })
        }

    },

    loadInfo(){
        api.get('donations-stats')
            .then(response => {

                this.statsAll = response.data;

            })
            .catch(error => {
                console.log(error);
                this.error = error.message;
            })
    },

    toggleAsset(asset) {
        if(this.network === "testnet" && asset !== "uluna") {
            return;
        }
        this.asset = asset;
    },

    resetForm() {
        this.asset = "uluna";
    },

    makeDonation() {
        this.loading = true
        this.error = null
        this.success = null

        const network = this.network

        api.post('donations/prepare', {
            amount: this.amount,
            asset: this.asset,
            network: network
        })
        .then(response => {
            console.log(response.data);

            //history.pushState({}, '', document.location.origin + '/#memo=' + response.data.memo);

            window.payWithTerra.start({
                ...response.data,
                showTestnetWarning: (network === 'testnet'),

                onClose: (hash) => {
                    console.log("onClose", hash);
                },
                onSuccess: (hash) => {
                    this.success = 'Donation added, thank you!';

                    this.loadInfo()

                    console.log("onSuccess", hash);
                }
            })

        })
        .catch(error => {
            console.log(error);
            this.error = error.response.data.message || error.message;
        })
        .finally(() => {
            this.loading = false;
        })
    }
})
